.button {
  --buttonSize: calc((56 / 16) * 1rem);
  --buttonFontSize: calc((18 / 16) * 1rem);
  --buttonFocusWidth: 4px;
  --buttonPadding: 0 var(--spaceL);
  --buttonTextColor: rgb(var(--rgbBackground));
  --buttonTextVisibility: visible;

  outline: none;
  height: var(--buttonSize);
  padding: var(--buttonPadding);
  cursor: pointer;
  transition-property: opacity, color, background;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: inline-flex;
  align-items: center;
  color: var(--buttonTextColor);
  position: relative;
  z-index: 1;

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
  }

  &::after {
    content: '';
    transition-property: opacity, color, background;
    transition-duration: var(--durationM);
    transition-timing-function: var(--bezierFastoutSlowin);
    background: rgb(var(--rgbPrimary));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
  }

  &:active {
    box-shadow: none;
    transition-duration: calc(var(--durationXS) / 2);
  }

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity, color, background;
    }

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }
}

.button--secondary {
  --buttonSpace: 10px;
  --buttonTextColor: rgb(var(--rgbPrimary));

  background: none;
  padding-left: var(--buttonSpace);
  padding-right: var(--buttonSpace);
  position: relative;
  left: calc(var(--buttonSpace) * -1);
  height: calc((32 / 16) * 1rem);

  &::after {
    content: '';
    height: calc((32 / 16) * 1rem);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(var(--rgbPrimary) / 0.2);
    transform: scale3d(0, 1, 1);
    transform-origin: right;
    clip-path: none;
  }

  &:hover {
    transform: none;
    background: transparent;
  }

  &:hover::after {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }

  @media (--mediaUseMotion) {
    &::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }
}

.button--loading {
  --buttonTextVisibility: hidden;
}

.button--icon-only {
  --buttonPadding: 0;
  --buttonTextColor: var(--colorTextBody);

  width: var(--buttonSize);
  align-items: center;
  justify-content: center;
  padding: 0;

  &:hover {
    transform: none;
  }

  &::after {
    background: rgb(var(--rgbText) / 0);
  }

  &:hover::after {
    background: rgb(var(--rgbText) / 0.1);
  }
}

.button__text {
  font-size: var(--buttonFontSize);
  font-weight: var(--fontWeightMedium);
  visibility: var(--buttonTextVisibility);
  position: relative;
  line-height: 1;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(var(--rgbBackground));
}

.button__icon {
  transition-property: opacity, fill;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity, fill;
    }
  }
}

.button__icon--start {
  margin-right: var(--spaceS);
}

.button__icon--end {
  margin-left: var(--spaceS);
}

@media (--mediaUseMotion) {
  .button:hover .button__icon--shift {
    transform: translate3d(var(--spaceXS), 0, 0);
  }
}

.button--loading .button__icon {
  opacity: 0;
}
